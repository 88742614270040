var userSubscriptions4i = function(){	
	const checkboxes = new checkboxes4i();
	const catalog = new catalogSubscription();
	const helpers = new formHelpers4i();
	const hasSMS = document.getElementById("SMSAlertsSection");
	const isPublicESP = document.getElementById("hdnEmail");
	const useRecaptcha = document.getElementById("UseRecaptcha");
	const catalogCheckbox = document.getElementById('CatalogCheckbox');
	const btnsParent = document.querySelector("#SaveChanges") ? document.querySelector("#SaveChanges").parentNode : null;
	const emailAddress = document.getElementById("hdnEmail") ? document.getElementById("hdnEmail").textContent.toString() : "";
	const userID = document.getElementById("hdnUserID") ? document.getElementById("hdnUserID").textContent.toString() : "";
	const messages = {
		Error: "Your subscription preferences failed to update. If this continues please <a href='/info/contactus'>contact us</a> for assistance.",
		Success: "Your subscription preferences have been updated. Continue to the homepage to <a href='/'>start shopping</a>."
	};
	let SMS;
	
	const init = () => {
		const espAccordions = new getAccordions4i();
		espAccordions.init(document.getElementById("mainContent"));
		
		if(hasSMS) SMS = new smsSubscription();
		catalog.Init();
		checkboxes.Init();
		unsubscribe.Init();
		subscribe.Init();
	};
	const unsubscribe = (function(){
		const init = () => {
			const unsubscribeBtn = document.getElementById("UnsubscribeAll");
			if(unsubscribeBtn){
				if(isPublicESP && useRecaptcha) unsubscribeBtn.addEventListener("click", publicUnsubscribeAll);
				else unsubscribeBtn.addEventListener("click", sendUnsubscribe);
			}
		};
		const sendUnsubscribe = async (e) => {
			e.preventDefault();
			helpers.ClearStatus();
			helpers.ShowBusyBtn(e.currentTarget);		
			let url = "", data = "";
			if(isPublicESP){
				const preferences = checkboxes.GetPreferences(document.querySelector(".emailSubscriptions")); 
				url = '/Esp/UnsubscribeAllEmails';
				data = JSON.stringify({
					emailPrefs : preferences, 
					emailAddress : emailAddress, 
					userID : userID
				});
			}else{
				url = '/Esp/UnsubscribeAllEmailsExcludeBlueBox';
			}
			
			try{
				const response = await fetch(url, {
					method: 'POST', 
					body: data, 
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
				});
				if(!response.ok) throw new Error(`Error processing unsubscribe esp request: ${response.statusText}`);
				unsubscribeSuccess();
			}catch(error){
				console.error(error);
				unsubscribeFail();
			}
		};
		const publicUnsubscribeAll = async (e) => {
			e.preventDefault();
			helpers.ClearStatus();
			helpers.ShowBusyBtn(e.currentTarget);	
			const preferences = checkboxes.GetPreferences(document.querySelector(".emailSubscriptions"));
				
			// Check if grecaptcha is defined
			// if not, try to fetch the script again 
			if(typeof grecaptcha == "undefined" && typeof get4iRecaptcha !== "undefined") await get4iRecaptcha();
			
			// need to show an error if recaptcha script still isn't available
			if(typeof grecaptcha == "undefined"){
				helpers.ShowErrorStatus(btnsParent, messages.Error);
				helpers.ClearBusyBtn();
			}else{
				grecaptcha.execute(helpers.RecaptchaKey, { action: helpers.RecaptchaName }).then(function(token) {
				        var unsubscribeData = {
						emailPrefs : preferences, 
						emailAddress : emailAddress, 
						userID : userID, 
						useRecaptcha : true, 
						recaptchaActionName : helpers.RecaptchaName, 
						recaptchaToken : token
					};
						        			
					fetch('/Esp/UnsubscribeAllEmails', { 
						method: 'POST', 
						body: JSON.stringify(unsubscribeData), 
						headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
					}).then(function(response){
						return  (response.ok) ? response.json() : []; // response.json() returns a promise
					}).then(function(response){
						if (response.success == "true") {
					    		unsubscribeSuccess();
					    	} else {
					    		helpers.ShowErrorStatus(btnsParent, response.recaptchaError);
					    		helpers.ClearBusyBtn();
					    	}
					}).catch(function(error){
						unsubscribeFail();
					});
			    	});
			}
		};
		const unsubscribeSuccess = () => {
			checkboxes.Uncheck(document.querySelector(".userSubscriptions").querySelectorAll("input[type='checkbox']"));
			helpers.ClearBusyBtn();
			if(catalogCheckbox) catalog.Unsubscribe();
			if(hasSMS) SMS.Unsubscribe();
			else helpers.ShowSuccessStatus(btnsParent, messages.Success);
		};
		const unsubscribeFail = () => {
			helpers.ClearBusyBtn();
	    		if (hasSMS) SMS.Unsubscribe();
	    		else helpers.ShowErrorStatus(btnsParent.parentNode, messages.Error);
		};
		return{
			Init: init
		};
	})();
	
	const subscribe = (function(){
		const init = () => {
			const saveChangeBtn = document.getElementById("SaveChanges");
			if(saveChangeBtn){
				if(isPublicESP && useRecaptcha) saveChangeBtn.addEventListener("click", publicSaveRequest);
				else saveChangeBtn.addEventListener("click", saveRequest);
			}
		};
		const saveRequest = async (e) => {
			e.preventDefault();
			helpers.ClearStatus();
			helpers.ShowBusyBtn(e.currentTarget);	
			const prefs = checkboxes.GetPreferences(document.querySelector(".userSubscriptions"));
			let url = "", data = "";
			if(isPublicESP){
				url = '/Esp/SavePublicUserSubscriptions';
				data = {
					emailPrefs : prefs, 
					emailAddress : emailAddress,
					userID : userID
				};
			}else{
				url = '/Esp/SaveEmailSubscriptionsExcludeBlueBox';
				data = { emailPrefs: prefs };
			}
			
			try{
				const response = await fetch(url, {
					method: 'POST', 
					body: JSON.stringify(data), 
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
				});
				if(!response.ok) throw new Error(`Error processing bluebox subscription request: ${response.statusText}`);
				saveSuccess();
			}catch(error){
				console.error(error);
				saveFail();
			}
		};
		const publicSaveRequest = async (e) => {	
			e.preventDefault();
			helpers.ClearStatus();
			helpers.ShowBusyBtn(e.currentTarget);	
			const prefs = checkboxes.GetPreferences(document.querySelector(".userSubscriptions"));
			
			// Check if grecaptcha is defined
			// if not, try to fetch the script again 
			if(typeof grecaptcha == "undefined" && typeof get4iRecaptcha !== "undefined") await get4iRecaptcha();
			
			// need to show an error if recaptcha script still isn't available
			if(typeof grecaptcha == "undefined"){
				helpers.ShowErrorStatus(btnsParent, messages.Error);
				helpers.ClearBusyBtn();
			}else{
			    	grecaptcha.execute(helpers.RecaptchaKey, { action: helpers.RecaptchaName }).then(function(token) {
			        	const saveUserData = {
						emailPrefs : prefs, 
						emailAddress : emailAddress,
						userID : userID, 
						useRecaptcha : true, 
						recaptchaActionName : helpers.RecaptchaName,
						recaptchaToken : token
					};
						        			
					fetch('/Esp/SavePublicUserSubscriptions', { 
						method: 'POST', 
						body: JSON.stringify(saveUserData), 
						headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
					}).then(function(response){
						return  (response.ok) ? response.json() : []; // response.json() returns a promise
					}).then(function(response){
						if(response.success == "true") saveSuccess();
					    	else saveFail();
					}).catch(function(error){
						saveFail();
					});
			    	});
			 }
		};
		const saveSuccess = () => {
			helpers.ClearBusyBtn();
			if(catalogCheckbox) catalog.Update();
	    		if(hasSMS) SMS.Update();
	    		else helpers.ShowSuccessStatus(btnsParent, messages.Success);
		};
		const saveFail = () => {
			helpers.ClearBusyBtn();
	    		if(hasSMS) SMS.Update();
	    		else helpers.ShowErrorStatus(btnsParent, messages.Error);
		};
		return{
			Init: init
		};
	})();

	return {
		Init: init	
	};
};

(function(){
	const userPreferences4i = new userSubscriptions4i();
	userPreferences4i.Init();
})();